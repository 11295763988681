import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Heading, Map } from '../../components';
import { fetchShortLocation } from '../../util/maps';
import { locatedOptions } from '../../config/configListingOptions';
import {
  isBoatListingType,
  isJetSkiListingType,
  isFishingListingType,
  isWatersportListingType,
} from '../../util/types';
import css from './ListingPage.module.css';

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: false };
  }

  render() {
    const { 
      className, 
      rootClassName, 
      geolocation, 
      publicData, 
      listingId, 
      mapsConfig, 
      showFullLocation, 
      currentUserTransactions,
      listingCategory
    } = this.props;

    if (!geolocation) {
      return null;
    }

    const address = publicData && publicData.location ? publicData.location.address : '';
    const classes = classNames(rootClassName || css.sectionMap, className);
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

    const mapProps = mapsConfig.fuzzy.enabled && !showFullLocation
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, mapsConfig.fuzzy.offset, cacheKey) }
      : { address, center: geolocation };
    const map = !!currentUserTransactions?.length ?
      <Map key="map-showFullLocation" {...mapProps} useStaticMap={this.state.isStatic} showFullLocation={showFullLocation} /> :
      <Map key="map-showFullLocation-false" {...mapProps} useStaticMap={this.state.isStatic} showFullLocation={showFullLocation} />;

    const listingLocatedAtOptions = locatedOptions;

    const savedListingLocatedAt =
      publicData?.listingLocatedAt ||
      publicData?.listingLocatedAtJetSki ||
      publicData?.listingLocatedAtFishing ||
      publicData?.listingLocatedAtWatersport

    const listingLocatedAt = savedListingLocatedAt && listingLocatedAtOptions.filter(item => savedListingLocatedAt.includes(item.option))

    const pickupLocationDescription =
      publicData?.pickupLocationDescription ||
      publicData?.pickupLocationDescriptionJetSki ||
      publicData?.pickupLocationDescriptionFishing ||
      publicData?.pickupLocationDescriptionWatersport;

    const destinationDescription =
      publicData?.destinationDescription ||
      publicData?.destinationDescriptionJetSki ||
      publicData?.destinationDescriptionFishing ||
      publicData?.destinationDescriptionWatersport;

    const locationContext = publicData?.locationContext;
    const shortLocation = !!locationContext?.length ? fetchShortLocation(locationContext, true)?.split(",") : null

    const isBoatListing = isBoatListingType(listingCategory);
    const isJetSkiListing = isJetSkiListingType(listingCategory);
    const isFishingListing = isFishingListingType(listingCategory);
    const isWatersportListing = isWatersportListingType(listingCategory);

    return (
      <div className={classes}>
        <Heading as="h3" rootClassName={css.sectionHeadingWithExtraMargin}>
          <FormattedMessage id="ListingPage.locationTitle" />
        </Heading>

        {!!shortLocation?.length && (
          <div className={css.locationItem}>
            <span className={css.locationItemTitle}>
              <FormattedMessage id="ListingPage.country" />
            </span>
            {shortLocation?.[shortLocation.length - 1]}
          </div>
        )}
        {!!shortLocation?.length && shortLocation?.length > 2 && (
          <div className={css.locationItem}>
            <span className={css.locationItemTitle}>
              <FormattedMessage id="ListingPage.state" />
            </span>
            {shortLocation?.[1]}
          </div>
        )}
        {!!shortLocation?.length && (
          <div className={css.locationItem}>
            <span className={css.locationItemTitle}>
              <FormattedMessage id="ListingPage.city" />
            </span>
            {shortLocation?.[0]}
          </div>
        )}
        {!!showFullLocation && (
          <div className={css.locationItem}>
            <span className={css.locationItemTitle}>
              <FormattedMessage id="ListingPage.address" />
            </span>
            {address}
          </div>
        )}
        {!!listingLocatedAt?.length && (
          <div className={css.locationItem}>
            <span className={css.locationItemTitle}>
              <FormattedMessage
                id={
                  isBoatListing ? "ListingPage.locatedAtBoat" :
                    isJetSkiListing ? "ListingPage.locatedAtJetSki" :
                      isFishingListing ? "ListingPage.locatedAtFishing" :
                        isWatersportListing ? "ListingPage.locatedAtWatersport" :
                          "ListingPage.locatedAt"
                }
              />
            </span>
            {listingLocatedAt.map(item => item.label).join(", ")}
          </div>
        )}

        {!!pickupLocationDescription && !!showFullLocation && (
          <div className={css.locationItem}>
            <span className={css.locationItemTitle}>
              <FormattedMessage id="ListingPage.pickupLocation" />
            </span>
            {pickupLocationDescription}
          </div>
        )}

        {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => {
              this.setState({ isStatic: false });
            }}
          >
            {map}
          </button>
        ) : (
          <div className={css.map}>{map}</div>
        )}

        {!!destinationDescription && !!showFullLocation && (
          <div className={css.locationItem}>
            <span className={css.locationItemTitle}>
              <FormattedMessage id="ListingPage.destinationDescription" />
            </span>
            {destinationDescription}
          </div>
        )}
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default SectionMapMaybe;
